import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { APP_ROUTES } from '@environments/routes/app-routes';
import { AuthFacade } from '@shared/auth-module/store/auth.facade';
import { first, map, Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _authFacade: AuthFacade, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._authFacade.isAuthenticated$.pipe(
      first(),
      map((res) => {
        if (!res) {
          this.router.navigate(APP_ROUTES.login());
        }
        return res;
      })
    );
  }
}
