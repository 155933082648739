import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationStart, ResolveEnd, Router } from '@angular/router';
import { fadeInOut } from '@core/animations/fade-in-out.animation';
import { GlobalLoaderService } from '@shared/loader/services/global-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  private __loaderService = inject(GlobalLoaderService);
  private __router = inject(Router);
  private __destroyRef = inject(DestroyRef);
  private _routingTimer: any;

  ngOnInit(): void {
    this.__router.events.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Saving URL for routerBack
        if (!!window.location.pathname && window.location.pathname !== '/undefined') {
          const routerUrl = new URL(`${window.location.origin}${event.url.toString()}`);
          const newUrl = `${routerUrl.pathname}${routerUrl.search}`;
          const oldUrl = `${window.location.pathname}${window.location.search}`;
          newUrl !== oldUrl && sessionStorage.setItem('router_back_url', oldUrl);
        }
      }

      if (event instanceof ResolveEnd) {
        this._routingTimer && clearTimeout(this._routingTimer);
        this._routingTimer = setTimeout(() => this.__loaderService.create('routing-loader'), 2000);

        // Saving URL for routerBack
        if (!!window.location.pathname && window.location.pathname !== '/undefined') {
          const routerUrl = new URL(`${window.location.origin}${event.url.toString()}`);
          const newUrl = `${routerUrl.pathname}${routerUrl.search}`;
          const oldUrl = `${window.location.pathname}${window.location.search}`;
          newUrl !== oldUrl && sessionStorage.setItem('router_back_url', oldUrl);
        }
      } else if (event instanceof NavigationEnd) {
        this._routingTimer && clearTimeout(this._routingTimer);
        this.__loaderService.dismissAll();
      }
    });
  }
}
