import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { GlobalLoaderService } from '@shared/loader/services/global-loader.service';

@Component({
  selector: 'global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalLoaderComponent {
  protected _loadersService = inject(GlobalLoaderService);
}
