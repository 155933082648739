import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular';

if (document.location.protocol === 'http:' && (environment.dev || environment.staging || environment.production)) {
  document.location.href = document.location.href.replace('http:', 'https:');
}

if (environment.staging || environment.production) {
  Sentry.init({
    dsn: 'https://ff3d729305fb890414073e4b90ec2143@sentry.octra.online/4',
    integrations: [
      Sentry.browserTracingIntegration()
      /**
       * Switched off for not longer than end of August to see how
       * replyIntegration functionality impacts performance
       * of our limited-in-resources sentry server
       */

      // Sentry.replayIntegration({
      //   maskAllText: false,
      //   blockAllMedia: false
      // })
    ],
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['https://octra-staging.groupm.com/', 'https://octra.groupm.com/'],
    tracesSampleRate: 1.0,
    environment: environment.ENV
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
