import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule } from '@angular/router';
import { OctraRoutes } from '@core/types/octra-route.type';
import { environment } from '@environments/environment';
import { sideNavNavigationDev } from '@environments/navigation';
import { APP_ROUTES } from '@environments/routes/app-routes';
import { ClientsOutletLayoutComponent } from '@layouts/clients-outlet-layout/clients-outlet-layout.component';
import { MainOutletLayoutComponent } from '@layouts/main-outlet-layout/main-outlet-layout.component';
import { MarketingOutletLayoutComponent } from '@layouts/marketing-outlet-layout/marketing-outlet-layout.component';
import { AuthGuard } from '@shared/auth-module/guards/auth.guard';
import { NotAuthGuard } from '@shared/auth-module/guards/not-auth.guard';
import { TiktokGuard } from '@shared/auth-module/guards/tiktok.guard';
import { SelectedClientGuard } from '@shared/clients/guards/selected-client.guard';

const routes: OctraRoutes = [
  {
    path: '',
    component: MarketingOutletLayoutComponent,
    loadChildren: () => import('../modules/marketing/marketing.module').then((m) => m.MarketingModule)
  },
  {
    path: `${APP_ROUTES.part.clients}/:clientSlug`,
    component: ClientsOutletLayoutComponent,
    canActivate: [AuthGuard, SelectedClientGuard],
    canActivateChild: [SelectedClientGuard],
    runGuardsAndResolvers: 'always',
    data: {
      type: 'client'
    },
    children: [
      {
        path: APP_ROUTES.part.client,
        loadChildren: () => import('../modules/client/client.module').then((m) => m.ClientModule)
      },
      {
        path: APP_ROUTES.part.governance,
        loadChildren: () => import('../modules/governance/governance.module').then((m) => m.GovernanceModule)
      },
      {
        path: APP_ROUTES.part.accounts,
        loadChildren: () => import('../modules/accounts/accounts.routes').then((m) => m.default)
      }
    ]
  },
  {
    path: '',
    component: ClientsOutletLayoutComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadChildren: () => import('../modules/profile/profile.module').then((m) => m.ProfileModule)
      }
    ]
  },
  {
    path: APP_ROUTES.part.admin,
    component: ClientsOutletLayoutComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { type: 'admin' },
    loadChildren: () => import('../modules/admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: APP_ROUTES.part.user_dashboard,
    canActivate: [AuthGuard, SelectedClientGuard],
    component: MainOutletLayoutComponent,
    data: { title: 'Dashboard', type: 'admin' },
    loadChildren: () => import('../modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: APP_ROUTES.part.login,
    canActivate: [NotAuthGuard],
    loadChildren: () => import('../modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: APP_ROUTES.part.tiktok,
    component: ClientsOutletLayoutComponent,
    canActivate: [AuthGuard, TiktokGuard]
  },
  {
    path: `${APP_ROUTES.part.error}`,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    component: ClientsOutletLayoutComponent,
    loadChildren: () => import('../modules/error/error.module').then((m) => m.ErrorModule)
  }
];

if (!environment.production) {
  routes.push({
    path: `dev`,
    data: {
      nav: sideNavNavigationDev
    },
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    component: ClientsOutletLayoutComponent,
    loadChildren: () => import('../modules/dev/dev.module').then((m) => m.DevModule)
  });
}

routes.push({
  path: '**',
  pathMatch: 'full',
  redirectTo: 'error/404'
});

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
