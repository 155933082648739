import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { APP_ROUTES } from '@environments/routes/app-routes';
import { AuthFacade } from '@shared/auth-module/store/auth.facade';
import { first, map, Observable } from 'rxjs';

@Injectable()
export class SuperuserGuard implements CanActivate, CanActivateChild {
  constructor(
    private _authFacade: AuthFacade,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._authFacade.isAuthenticated$.pipe(
      first(),
      map((res) => {
        if (!res || !this._authFacade.user?.is_superuser) {
          this.router.navigate(APP_ROUTES.admin.clients());
        }
        return res;
      })
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._authFacade.isAuthenticated$.pipe(
      first(),
      map((res) => {
        if (!res || !this._authFacade.user?.is_superuser) {
          this.router.navigate(APP_ROUTES.admin.clients());
        }
        return res;
      })
    );
  }
}
