<dialog-modal
  titleAlign="left"
  contentAlign="left"
  class="disabled-accounts-popup"
  [config]="{ disposeOnNavigation: false }"
  [title]="popupTitle"
  [content]="popupContent"
  [buttons]="popupButtons"
/>
<ng-template #popupTitle>
  {{ _accountsLength }} disabled accounts have been disconnected
  <div class="subtitle">
    We detected {{ _accountsLength }} disabled accounts. As we are not able to check the data for them anymore, they have been disconnected.
  </div>
</ng-template>
<ng-template #popupContent>
  <ngx-table #table [dataSource]="_tableSource$" class="mdc-data-table__table--borderless">
    <ng-template ngxColumn="name" header="Account" let-account>
      @if (account) {
        <div class="d-flex align-items-center">
          <img src="assets/platforms/{{ account.platform_name }}.svg" />
          <div>
            @if (account?.parent_name) {
              <span>{{ account?.parent_name }}</span>
            }
            {{ account.name }}
          </div>
        </div>
      }
    </ng-template>
    <ng-template ngxColumn="business_unit_id" header="Business Unit" let-account>
      <div class="business-unit-preview">{{ account.business_unit_name || _clientName }}</div>
    </ng-template>
  </ngx-table>
</ng-template>
<ng-template #popupButtons>
  <button type="button" mat-flat-button color="primary" (click)="_confirm()"><mat-icon>check</mat-icon> Confirm and continue</button>
</ng-template>
