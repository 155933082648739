@if (_$showClientLogo()) {
  <img [src]="_$client().logo_url" [attr.alt]="_$client().name" class="mt-24 h-max-66 mx-auto" />
}

<div class="align-self-stretch pb-140 flex-grow-1 pt-24">
  @for (group of _$nav(); track group.label) {
    <client-layout-side-nav-group [group]="group" [$accountsErrors]="accountsErrors" />
  }
</div>

<div class="position-absolute end-0 bottom-0 start-0">
  <div class="pt-16 bg-color-white mx-8 d-flex flex-column align-items-center">
    <a
      href="https://insidemedia.sharepoint.com/sites/OCTRAKnowledgeBase"
      target="_blank"
      mat-stroked-button
      class="mb-16 mx-24 when-expanded"
    >
      <mat-icon class="outlined">help</mat-icon> <span class="text-truncate">Help & Feedback</span>
    </a>
    <a
      href="https://insidemedia.sharepoint.com/sites/OCTRAKnowledgeBase"
      target="_blank"
      mat-icon-button
      class="mb-12 when-collapsed align-items-center justify-content-center"
    >
      <mat-icon class="outlined w-max-20 h-max-20 fs-20">help</mat-icon></a
    >
  </div>

  <div class="border-top-1 border-color-neutral-variant--400"></div>

  <div class="bg-color-white mx-8 d-flex flex-column align-items-center">
    <div class="px-24 py-20 fs-10 lh-15 color-fonts--note-100 text-center flex-column when-expanded">
      <div class="text-truncate">Octra {{ _$dateNow() | date: 'YYYY' }}. All rights reserved.</div>
      <a class="color-fonts--primary" [routerLink]="_APP_ROUTES.privacyPolicy()">Privacy Policy</a>
    </div>
    <div class="py-20 fs-10 lh-15 color-fonts--note-100 text-center flex-column when-collapsed">
      Octra {{ _$dateNow() | date: 'YYYY' }}.<br />All rights reserved.<br /><a
        class="color-fonts--primary"
        [routerLink]="_APP_ROUTES.privacyPolicy()"
        >Privacy Policy</a
      >
    </div>
  </div>
</div>
