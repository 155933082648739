import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { Tokens } from '@okta/okta-auth-js';
import { AuthService } from '@shared/auth-module/services/auth.service';
import { authActions } from '@shared/auth-module/store/auth.actions';
import { authSelectors } from '@shared/auth-module/store/auth.selectors';
import { AuthType } from '@shared/auth-module/types/auth.type';
import { UserService } from '@shared/users-module/services/user.service';
import { UserTokens } from '@shared/users-module/types/user-tokens.type';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { catchError, from, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class AuthEffects {
  verify$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(authActions.verify),
      switchMap(() => {
        const tokensString: string | null = localStorage.getItem('tokens');

        if (tokensString) {
          const tokens: UserTokens = JSON.parse(tokensString);

          if (tokens.access_token && tokens.access_token !== '') {
            return of(authActions.verifySuccess({ tokens }));
          }
        }

        return of(authActions.verifyFailed());
      })
    )
  );

  verifySuccess$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(authActions.verifySuccess),
      map((action) => action.tokens),
      tap((tokens: UserTokens) => this.__store.dispatch(authActions.setTokens({ tokens }))),
      switchMap((tokens: UserTokens) =>
        tokens.engine === AuthType.OKTA && tokens.refresh_token ? from(this.__oktaAuth.tokenManager.getTokens()) : of(null)
      ),
      tap((tokens: Tokens) => {
        if (tokens && tokens.idToken && tokens.accessToken) {
          this.__oktaAuth.tokenManager.setTokens(tokens);
        }
      }),
      /**
       * TODO: v1 API flow will be updated after v2 Auth API is supported on all environments
       */
      switchMap(() => this.__userService.v1_getUserCurrent()),
      switchMap((user) => {
        this.__store.dispatch(authActions.setUser({ user }));
        return of(authActions.verifyFinished(), authActions.loginSuccess());
      })
    )
  );

  verifyFailed$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(authActions.verifyFailed),
      switchMap(() => of(authActions.verifyFinished(), authActions.logout()))
    )
  );

  login$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(authActions.login),
      switchMap((action) =>
        this.__authService.login(action.authType, action.login, action.password).pipe(
          tap(() => this.__store.dispatch(authActions.clearError())),
          tap((tokens: UserTokens) => this.__store.dispatch(authActions.setTokens({ tokens }))),
          switchMap((tokens) =>
            this.__authService.v1_loginApi(tokens.access_token).pipe(
              switchMap(() =>
                this.__userService.v1_getUserCurrent().pipe(
                  map((user) => {
                    this.__store.dispatch(authActions.setUser({ user }));
                    this.__gtmService.pushTag({ octra_user_id: user.id });
                    return authActions.loginSuccess();
                  }),
                  catchError((error) => {
                    this.__store.dispatch(
                      authActions.setError({ message: error?.error?.message || 'Failed to login, please try again later.' })
                    );

                    return of(authActions.loginFailed());
                  })
                )
              )
            )
          ),
          catchError((error) => {
            this.__store.dispatch(authActions.setError({ message: error?.error?.message || 'Failed to login, please try again later.' }));
            return of(authActions.loginFailed());
          })
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(authActions.loginSuccess),
      concatLatestFrom(() => [this.__store.select(authSelectors.selectAuthTokens)]),
      map(([, tokens]) => {
        if (tokens) {
          localStorage.setItem('tokens', JSON.stringify(tokens));
        }
      }),
      switchMap(() => of(authActions.loginSuccessAfter()))
    )
  );

  logout$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(authActions.logout),
      tap(() => this.__store.dispatch(authActions.clearError())),
      map(() => {
        localStorage.removeItem('tokens');
        this.__oktaAuth.tokenManager.clear();
        return authActions.logoutSuccess();
      })
    )
  );

  constructor(
    private __actions$: Actions,
    private __store: Store,
    private __authService: AuthService,
    private __userService: UserService,
    private __gtmService: GoogleTagManagerService,
    @Inject(OKTA_AUTH) private __oktaAuth: OktaAuth
  ) {}
}
