@for (loader of _loadersService?.$loaders(); track loader) {
  <div [ngClass]="[loader?.type || '']" [attr.aria-label]="loader.loader.uuid">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="lds-dual-ring"></div>
  </div>
}
