import { ModuleWithProviders, NgModule } from '@angular/core';
import { LeaveEditModeGuard } from '@shared/route-change/guards/leave-edit-mode.guard';
import { RouteChangingService } from '@shared/route-change/services/route-changing.service';

@NgModule()
export class RouteChangeSharedModule {
  static forRoot(): ModuleWithProviders<RouteChangeSharedModule> {
    return {
      ngModule: RouteChangeSharedModule,
      providers: [RouteChangingService, LeaveEditModeGuard]
    };
  }
}
