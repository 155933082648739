<button
  mat-icon-button
  (click)="layout.toggleSidenav()"
  [matTooltip]="layout.$sidenavExpanded() ? 'Hide side navigation' : 'Show side navigation'"
>
  @if (layout.$sidenavExpanded()) {
    <mat-icon>menu_open</mat-icon>
  } @else {
    <mat-icon>menu</mat-icon>
  }
</button>

<img src="/assets/octra.svg" class="ms-32 me-72 h-max-20" [ngClass]="{ 'd-none': isIframe }" />
<div class="me-72" [ngClass]="{ 'd-none': !isIframe }"></div>

<business-unit-navigation [ngClass]="{ 'd-none': !_pageWithClientSlug() }" />

<platform-dropdown [ngClass]="{ 'd-none': !_pageWithClientSlug() }" class="ms-24" />

<span class="ms-auto"></span>

@if (!!_user.impersonator) {
  <div class="d-flex align-items-center px-8 py-2 fs-12 rounded-4 border-1 border-color-secondary--300 bg-color-secondary--100 me-72">
    <mat-icon class="outlined color-fonts--note-200 ms-4 me-12 mi-size-14">warning</mat-icon>
    Impersonate mode
    <div class="fw-bold cursor-pointer p-4 ms-8" (click)="v1_stopImpersonation()" matTooltip="Stop impersonation">Exit</div>
  </div>
}

<button [routerLink]="APP_ROUTES.admin.clients()" mat-icon-button color="primary" aria-label="home" matTooltip="Clients list">
  <mat-icon class="outlined">home</mat-icon>
</button>

<a
  href="https://insidemedia.sharepoint.com/sites/OCTRAKnowledgeBase"
  target="_blank"
  mat-icon-button
  class="align-items-center justify-content-center"
  matTooltip="Help & Feedback"
>
  <mat-icon class="outlined w-max-20 h-max-20 fs-20">help</mat-icon>
</a>

<span class="h-16 h-min-16 border-right-1 border-color-neutral-variant--400 ms-4 me-12"></span>
@if (_user) {
  <div class="d-flex align-items-center px-8 cursor-pointer" [ngxdropdowntoggle]="profilePopup">
    <div class="octra-user" [picture]="_user.picture" [initials]="_user.name"></div>
    <mat-icon>expand_more</mat-icon>
  </div>
}

<div
  ngxdropdown
  [ngxdropdownoptions]="{ position: 'bottomleft', showOverlay: false, edgePadding: -5 }"
  #profilePopup="ngxdropdown"
  class="profile-dropdown shadow-3 bg-color-white px-28 py-18 rounded-4 d-flex flex-column"
>
  <div class="octra-user" [picture]="_user.picture">
    <label>{{ _user.name }} </label>
    <summary>{{ _user.email }}</summary>
  </div>
  <span class="w-100p w-min-100p border-top-1 border-color-neutral-variant--400 my-12"></span>
  <a
    class="d-flex align-items-center color-fonts--note-100 color-fonts--primary--hover color-animate text-decoration-none py-6"
    [routerLink]="APP_ROUTES.profile.settings()"
  >
    <mat-icon class="outlined me-10">settings</mat-icon> User profile settings
  </a>
  <!-- Don't remove this button completely because it is commented for the future release roadmap in scope of OCTRA-5686 -->
  <!-- <a
    class="d-flex align-items-center color-fonts--note-100 color-fonts--primary--hover color-animate text-decoration-none py-6"
    [routerLink]="_appRoutes.profile.roadmap()"
  >
    <mat-icon class="outlined me-10">new_releases</mat-icon> Release roadmap
  </a> -->
  <a
    href="javascript:void(0)"
    class="d-flex align-items-center color-fonts--note-100 color-fonts--primary--hover color-animate text-decoration-none py-6"
    (click)="logout()"
  >
    <mat-icon class="me-10">logout</mat-icon> Sign out
  </a>
  <div class="bg-color-white mt-8 d-flex flex-column align-items-start">
    <div class="mt-32 fs-10 lh-15 color-fonts--note-100">
      <div class="text-truncate">
        Octra {{ $dateNow() | date: 'YYYY' }}. All rights reserved.
        <a class="color-fonts--primary" [routerLink]="APP_ROUTES.privacyPolicy()">Privacy Policy</a>
      </div>
    </div>
  </div>
</div>
