@if ({ group: _$group(), items: _$items(), params: _$routeParams() }; as scope) {
  @if (scope.group.route) {
    <a
      class="d-flex align-items-center py-8 text-decoration-none color-animate color-fonts--note-100 color-fonts--secondary--hover fs-16 lh-24 icon"
      [ngClass]="{ 'w-max-26': !_layout.$sidenavExpanded() }"
      routerLinkActive="color-fonts--primary font-weight-500"
      [routerLink]="scope.group.route(scope.params)"
    >
      <mat-icon
        class="w-min-24"
        [ngClass]="[scope.group.iconClass || '', getErrorDotStyle(scope.group.label)]"
        [matTooltip]="scope.group.label"
        matTooltipPosition="after"
        [matTooltipDisabled]="_layout.$sidenavExpanded()"
        >{{ scope.group.icon }}</mat-icon
      >
      <span class="text-truncate ps-8 when-expanded">{{ scope.group.label }}</span>
      @if (showFailedAccountsBadge(scope.group.label)) {
        <app-failed-accounts-badge class="ms-auto" [$accountsErrors]="$accountsErrors()" [$type]="_failureType.TOTAL" />
      }
    </a>
  } @else {
    <div
      class="d-flex align-items-center py-8 color-fonts--note-100 color-animate fs-16 lh-24 icon"
      [ngClass]="{ 'w-max-26': !_layout.$sidenavExpanded(), 'color-fonts--secondary--hover cursor-pointer': scope.items?.length }"
      (click)="_toggle()"
    >
      <mat-icon
        class="w-min-24"
        [ngClass]="[scope.group.iconClass || '', getErrorDotStyle(scope.group.label)]"
        [matTooltip]="scope.group.label"
        matTooltipPosition="after"
        [matTooltipDisabled]="_layout.$sidenavExpanded() || !!scope.items?.length"
        >{{ scope.group.icon }}</mat-icon
      >
      <span class="text-truncate ps-8 when-expanded">{{ scope.group.label }}</span>
      @if (showFailedAccountsBadge(scope.group.label)) {
        <app-failed-accounts-badge class="ms-auto" [$accountsErrors]="$accountsErrors()" [$type]="_failureType.TOTAL" />
      }
      @if (scope.items?.length) {
        <mat-icon class="w-min-24 ms-auto icon__toggle when-expanded">expand_less</mat-icon>
      }
    </div>
    @if (scope.items?.length) {
      <div class="items">
        <ul>
          @for (item of scope.items; track item.label) {
            <li
              class="d-flex align-items-center position-relative"
              [matTooltip]="item.tooltip ? item.tooltip : ''"
              matTooltipPosition="above"
            >
              <a
                class="subitem d-block text-decoration-none color-animate color-fonts--note-100 color-fonts--secondary--hover text-truncate ms-24 ps-8 py-8 pe-8"
                [ngClass]="{ disabled: item.disabled }"
                routerLinkActive="active color-fonts--primary font-weight-500"
                [routerLink]="item.route(scope.params)"
                >{{ item.label }}
              </a>
            </li>
          }
        </ul>
      </div>
    }
  }
  @if (scope.items?.length) {
    <div
      class="items__dropdown shadow-3 bg-color-white px-28 py-18 rounded-4 d-flex flex-column"
      [ngClass]="{ 'was-left': _$wasLeft() }"
      (mouseout)="_$wasLeft.set(true)"
    >
      <div>
        <div></div>
      </div>
      <h4 class="color-fonts--primary fs-16 lh-24 text-truncate m-0 p-0 pb-8">{{ scope.group.label }}</h4>
      <ul>
        @for (item of scope.items; track item.label) {
          <li
            class="d-flex align-items-center position-relative"
            [matTooltip]="item.tooltip ? item.tooltip : ''"
            matTooltipPosition="above"
          >
            <a
              class="subitem d-block text-decoration-none color-animate color-fonts--note-100 color-fonts--secondary--hover text-truncate ps-8 py-8"
              routerLinkActive="active color-fonts--primary font-weight-500"
              [ngClass]="{ disabled: item.disabled }"
              [routerLink]="item.route(scope.params)"
              >{{ item.label }}
            </a>
          </li>
        }
      </ul>
    </div>
  }
}
