import { Injectable } from '@angular/core';
import { EditState } from '@shared/route-change/types/edit-state.type';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class RouteChangingService {
  public _editState: EditState = {
    editState: false,
    page: ''
  };
  private _editState$ = new ReplaySubject<EditState>(1);

  get editState(): EditState {
    return this._editState;
  }

  setEditState(state: EditState): void {
    this._editState = state;
    this._editState$.next(this._editState);
  }
}
