import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthGuard } from '@shared/auth-module/guards/auth.guard';
import { NotAuthGuard } from '@shared/auth-module/guards/not-auth.guard';
import { SuperuserGuard } from '@shared/auth-module/guards/superuser.guard';
import { TiktokGuard } from '@shared/auth-module/guards/tiktok.guard';
import { AuthInterceptor } from '@shared/auth-module/interceptors/auth.interceptor';
import { RefreshTokenInterceptor } from '@shared/auth-module/interceptors/refresh-token.interceptor';
import { AuthService } from '@shared/auth-module/services/auth.service';
import { AuthEffects } from '@shared/auth-module/store/auth.effects';
import { AuthFacade } from '@shared/auth-module/store/auth.facade';
import { authReducer } from '@shared/auth-module/store/auth.reducer';

@NgModule({
  imports: [StoreModule.forFeature('auth', authReducer), EffectsModule.forFeature([AuthEffects])]
})
export class AuthSharedModule {
  static forRoot(): ModuleWithProviders<AuthSharedModule> {
    return {
      ngModule: AuthSharedModule,
      providers: [
        AuthService,
        AuthGuard,
        SuperuserGuard,
        NotAuthGuard,
        AuthFacade,
        TiktokGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RefreshTokenInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (authFacade: AuthFacade) => () => authFacade.init(),
          deps: [AuthFacade],
          multi: true
        }
      ]
    };
  }
}
