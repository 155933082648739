<section class="h-83 h-min-83 h-max-83 bg-color-white d-flex align-items-center px-24 position-sticky top-0 z-1">
  <a href="https://octra.groupm.com" class="me-24">
    <img src="assets/octra.svg" alt="Octra" class="h-45 h-max-45" />
  </a>
  <h2 class="font-weight-500 fs-14 lh-20">{{ _$title() }}</h2>
  <div class="d-flex align-items-start ms-auto">
    <a routerLink="/login" mat-raised-button>Sign in</a>
    <div class="ms-20 d-flex flex-column align-items-center">
      <a href="https://chos-help.choreograph.com/hc/en-us/requests/new" target="_blank" mat-stroked-button>Contact us</a>
      <span class="font-weight-500 fs-11 lh-16 mt-4">and get access for your team</span>
    </div>
  </div>
</section>

<router-outlet></router-outlet>

<section class="bg-color-dark d-flex flex-column align-items-center mt-auto">
  <p class="color-white font-weight-400 fs-35 lh-44">Get access to OCTRA for your team now!</p>
  <a href="https://chos-help.choreograph.com/hc/en-us/requests/new" target="_blank" mat-stroked-button>Contact us</a>

  <div class="border-top-1 border-color-white mx-72 mt-56 pt-32 pb-40 align-self-stretch d-flex">
    <a class="color-white fs-11 lh-16 font-weight-bold" [routerLink]="_APP_ROUTES.privacyPolicy()">Privacy policy</a>
    <span class="color-white fs-11 lh-16 font-weight-bold ms-auto">&#64; EssenceMediacom, {{ _dateNow | date: 'YYYY' }}</span>
  </div>
</section>
