import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { QaCheckService } from '@shared/qa-checklists/services/qa-check.service';
import { QaChecklistService } from '@shared/qa-checklists/services/qa-checklist.service';
import { QaResultService } from '@shared/qa-checklists/services/qa-result.service';

@NgModule({
  imports: [CommonModule, HttpClientModule]
})
export class QaChecklistsSharedModule {
  static forRoot(): ModuleWithProviders<QaChecklistsSharedModule> {
    return {
      ngModule: QaChecklistsSharedModule,
      providers: [QaChecklistService, QaCheckService, QaResultService]
    };
  }
}
