import { ModuleWithProviders, NgModule } from '@angular/core';
import { CSVDownloadService } from '@shared/csv/services/csv-download.service';

@NgModule({})
export class CSVSharedModule {
  static forRoot(): ModuleWithProviders<CSVSharedModule> {
    return {
      ngModule: CSVSharedModule,
      providers: [CSVDownloadService]
    };
  }
}
