import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  HostBinding,
  inject,
  OnInit,
  signal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, ActivatedRouteSnapshot, ResolveEnd, Router, RouterLink } from '@angular/router';
import { RouteHelper } from '@core/helpers/route.helper';
import { OctraNavigation } from '@core/types/octra-navigation.type';
import { environment } from '@environments/environment';
import { sideNavigation } from '@environments/navigation';
import { ClientsOutletLayoutComponent } from '@layouts/clients-outlet-layout/clients-outlet-layout.component';
import { ClientLayoutSideNavGroupComponent } from '@layouts/clients-outlet-layout/components/client-layout-side-nav-group/client-layout-side-nav-group.component';
import { AccountsRestService } from '@shared/accounts/services/accounts-rest.service';
import { AccountErrors } from '@shared/accounts/types/account-errors.type';
import { AuthFacade } from '@shared/auth-module/store/auth.facade';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { PlatformsService } from '@shared/platforms/services/platforms.service';
import _ from 'lodash';
import { filter, map } from 'rxjs';

@Component({
  standalone: true,
  selector: 'client-layout-side-nav',
  templateUrl: './client-layout-side-nav.component.html',
  styleUrl: './client-layout-side-nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ClientLayoutSideNavGroupComponent, DatePipe, RouterLink, MatIconModule, MatButtonModule]
})
export class ClientLayoutSideNavComponent implements OnInit {
  private __destroyRef = inject(DestroyRef);
  private __router = inject(Router);
  private __route = inject(ActivatedRoute);
  private __layout = inject(ClientsOutletLayoutComponent);
  private __clientFacade = inject(ClientFacade);
  private __authFacade = inject(AuthFacade);
  private __platformsService = inject(PlatformsService);
  private __accountsService = inject(AccountsRestService);
  private __cdr = inject(ChangeDetectorRef);
  private __$navSource = signal(this.__traverseRouteData(this.__router.routerState.snapshot.root) || sideNavigation);
  private __$user = toSignal(this.__authFacade.user$);
  private __$routeClientSlug = toSignal(
    this.__route.paramMap.pipe(
      takeUntilDestroyed(),
      map((params) => params.get('clientSlug'))
    )
  );
  private __$routerNav = toSignal(
    this.__router.events.pipe(
      takeUntilDestroyed(this.__destroyRef),
      filter((e) => e instanceof ResolveEnd),
      map((e) =>
        e instanceof ResolveEnd
          ? RouteHelper.traverseRouteData<OctraNavigation[]>(e.state.root, (route) => {
              const nav: OctraNavigation[] = route?.routeConfig?.data?.nav;

              if (nav && nav.length) {
                return route.routeConfig.data.nav;
              }

              return undefined;
            }) || sideNavigation
          : undefined
      )
    )
  );
  $client = this.__clientFacade.$selectedClient;
  $nav = computed(() => {
    const client = this.$client();
    const user = this.__$user();
    let nav = this.__$routerNav() || this.__$navSource();
    nav = nav.filter((group) => {
      const isRoleAllowed = !group.roles || !group.roles.length || !!client?.hasRole(group.roles);
      const isSuperuser = 'forSuperuser' in group && group.forSuperuser === true ? !!user.is_superuser : true;
      const isPriviledgeAllowed = !group.privileges || !group.privileges.length || !!client?.hasPrivilege(group.privileges);
      const isClientAllowed = group.clientCond === 'withSelectedClient' && !!client?.hasModule(group.requiredModule);
      const isNoClientAllowed = group.clientCond === 'withoutSelectedClient' && !client;
      const allowedAnyway = group.clientCond === 'always' || !group.clientCond;
      return isSuperuser && (isClientAllowed || isNoClientAllowed || allowedAnyway) && isRoleAllowed && isPriviledgeAllowed;
    });
    return this.__restrictNodes(nav);
  });
  $showClientLogo = computed(() => {
    const client = this.$client();
    const nav = this.$nav();
    const routeClientSlug = this.__$routeClientSlug();
    return client && nav.some((group) => !group.clientVisibility || group.clientVisibility === 'visible') && !!routeClientSlug;
  });
  accountsErrors: AccountErrors[] = [];

  constructor() {
    effect(() => {
      const client = this.$client();
      this.__getAccountErrors(client?.slug);
    });
  }

  @HostBinding('class') protected get cssClass(): string[] {
    const isSidenavExpanded = this.__layout.$sidenavExpanded();
    const cssClass = ['d-flex', 'flex-column', 'align-items-center', 'justify-content-start'];

    isSidenavExpanded && cssClass.push('sidenav-expanded');

    return cssClass;
  }

  ngOnInit(): void {
    this.__getAccountErrors(this.$client()?.slug);
  }

  isLastChild(index: number, total: number): boolean {
    return index === total - 1;
  }

  private __traverseRouteData(route: ActivatedRouteSnapshot): OctraNavigation[] {
    const nav: OctraNavigation[] = route?.routeConfig?.data?.nav;

    if (nav && nav.length) {
      return route.routeConfig.data.nav;
    }

    return route?.firstChild ? this.__traverseRouteData(route.firstChild) : undefined;
  }

  private __restrictNodes(nav: OctraNavigation[]): OctraNavigation[] {
    const navCopy = _.cloneDeep(nav);
    for (const node of navCopy) {
      this.__checkNode(node);
    }
    return navCopy;
  }

  private __checkNode(node: OctraNavigation): void {
    if (node.label === 'Anomaly detection') {
      if (
        !this.__platformsService.$platformsPreferred().filter((platform) => !environment.platforms.unavailableForAD.includes(platform.name))
          .length
      ) {
        node.disabled = true;
        node.tooltip =
          'To enable Anomaly Detection please select at least one platform that supports metrics in the preferred platforms settings';
      }
    }
    if (node.items) {
      for (const item of node.items) {
        this.__checkNode(item);
      }
    }
  }

  private __getAccountErrors(clientSlug?: string): void {
    if (!clientSlug) {
      return;
    }

    const platforms = this.__platformsService.$platformsPreferred();
    this.__accountsService
      .getAccountErrors(
        this.$client().slug,
        platforms.map((platform) => platform.name)
      )
      .pipe(takeUntilDestroyed(this.__destroyRef))
      .subscribe((accountErrors) => {
        this.accountsErrors = accountErrors;
        this.__cdr.markForCheck();
      });
  }
}
