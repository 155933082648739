import { createReducer, on } from '@ngrx/store';
import { authActions } from '@shared/auth-module/store/auth.actions';
import { User } from '@shared/users-module/models/user.model';
import { UserTokens } from '@shared/users-module/types/user-tokens.type';

export interface AuthState {
  initialized: boolean;
  tokens: UserTokens;
  isAuthenticated: boolean;
  user: User;
  error: string;
}

const initialState: AuthState = {
  initialized: undefined,
  tokens: {
    engine: undefined,
    access_token: '',
    refresh_token: '',
    refresh_at: undefined
  },
  isAuthenticated: undefined,
  user: undefined,
  error: undefined
};

export const authReducer = createReducer(
  initialState,
  on(authActions.login, (state): AuthState => ({ ...state })),
  on(authActions.loginSuccess, (state): AuthState => ({ ...state, isAuthenticated: !!state.user })),
  on(authActions.verifyFinished, (state): AuthState => ({ ...state, initialized: true })),
  on(authActions.logoutSuccess, (state): AuthState => ({ ...state, isAuthenticated: false, tokens: undefined })),
  on(authActions.setTokens, authActions.verifySuccess, (state, { tokens }): AuthState => ({ ...state, tokens })),
  on(authActions.setUser, (state, { user }): AuthState => ({ ...state, user })),
  on(authActions.setError, (state, { message }): AuthState => ({ ...state, error: message })),
  on(authActions.clearError, (state): AuthState => ({ ...state, error: undefined }))
);
