import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { APP_ROUTES } from '@environments/routes/app-routes';
import { QaService } from '@modules/governance/pages/qa-checklists/qa.service';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { PlatformsAuthService } from '@shared/platforms/services/platforms-auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable()
export class TiktokGuard implements CanActivate {
  constructor(
    private __router: Router,
    private __clientFacade: ClientFacade,
    private __platformsAuthService: PlatformsAuthService,
    private __toastService: ToastrService,
    private __qaService: QaService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const storageRedirectRaw = localStorage.getItem('tiktok_redirect_data');
    const storageRedirectionData: { redirectUrl?: string; start_sync: boolean } = storageRedirectRaw
      ? JSON.parse(storageRedirectRaw)
      : null;
    let urlTree = this.__router.parseUrl(storageRedirectionData?.redirectUrl || APP_ROUTES.admin.clients().join('/'));

    localStorage.removeItem('tiktok_redirect_data');

    this.__clientFacade.selectedClient?.slug &&
      (urlTree = this.__router.parseUrl(
        storageRedirectionData?.redirectUrl ||
          APP_ROUTES.client.connect_accounts(this.__clientFacade.selectedClient?.slug).join('/') + '?platform=tiktok'
      ));

    if (route.queryParams.auth_code) {
      return this.__platformsAuthService
        .authorizePlatform({
          platform_name: 'tiktok',
          code: route.queryParams.auth_code,
          start_sync: !!storageRedirectionData?.start_sync
        })
        .pipe(
          map((response) => {
            console.log('Tiktok platform auth success', response);
            this.__qaService.showPopupImmediately = true;
            return urlTree;
          }),
          catchError((error) => {
            this.__toastService.error(error.message || 'Something went wrong, could not log in to TikTok platform');
            return of(urlTree);
          })
        );
    }

    return urlTree;
  }
}
