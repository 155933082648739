import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RoadmapService } from '@shared/roadmap/services/roadmap.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
  providers: []
})
export class RoadmapSharedModule {
  static forRoot(): ModuleWithProviders<RoadmapSharedModule> {
    return {
      ngModule: RoadmapSharedModule,
      providers: [RoadmapService]
    };
  }
}
