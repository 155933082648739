import { CommonModule } from '@angular/common';
import { afterNextRender, ChangeDetectionStrategy, Component, computed, effect, HostBinding, inject, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { OctraNavigation } from '@core/types/octra-navigation.type';
import { ClientsOutletLayoutComponent } from '@layouts/clients-outlet-layout/clients-outlet-layout.component';
import {
  FailedAccountsBadgeComponent,
  FailureType
} from '@shared/accounts/components/failed-accounts-badge/failed-accounts-badge.component';
import { AuthFacade } from '@shared/auth-module/store/auth.facade';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { AccountErrors } from '@shared/accounts/types/account-errors.type';

@Component({
  standalone: true,
  selector: 'client-layout-side-nav-group',
  templateUrl: './client-layout-side-nav-group.component.html',
  styleUrl: './client-layout-side-nav-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule, MatIconModule, CommonModule, MatTooltipModule, FailedAccountsBadgeComponent]
})
export class ClientLayoutSideNavGroupComponent {
  private __clientFacade = inject(ClientFacade);
  private __authFacade = inject(AuthFacade);
  protected _layout = inject(ClientsOutletLayoutComponent);
  protected _$client = this.__clientFacade.$selectedClient;
  protected _$user = toSignal(this.__authFacade.user$);
  $group = input.required<OctraNavigation>({
    alias: 'group'
  });
  $accountsErrors = input.required<AccountErrors[]>();

  protected _$group = computed(() => {
    const [icon, iconClass] = this.$group().icon || ['', ''];
    return { ...this.$group(), icon, iconClass: iconClass || '' };
  });
  protected _$routeParams = computed(() => ({
    currentClientSlug: this._$client()?.slug
  }));
  protected _$isExpanded = computed(() => {
    const group = this._$group();
    const expander = this._expander();

    if (expander !== undefined) {
      return expander;
    }

    const code = group.label.toLowerCase().replace(/ /g, '-');
    const storage = localStorage.getItem(`sidenav-group-${code}`);
    return !!storage || storage === 'true';
  });
  protected _expander = signal<boolean>(undefined);
  protected _$wasLeft = signal(false);
  protected _$items = computed(() => {
    const group = this._$group();
    const client = this._$client();
    const user = this._$user();
    return group?.items?.filter((item) => {
      const isSuperuser = 'forSuperuser' in group && group.forSuperuser === true ? !!user.is_superuser : true;
      const clientAllowed =
        client &&
        (!item.roles || !item.roles.length || client.hasRole(item.roles)) &&
        (!item.privileges || !item.privileges.length || client.hasPrivilege(item.privileges)) &&
        ((!client && group.clientCond !== 'withSelectedClient') || (!!client && client.hasModule(item.requiredModule))) &&
        (!item.clientsNotVisible || !item.clientsNotVisible.includes(client.slug));
      return isSuperuser && clientAllowed;
    });
  });
  protected _failureType = FailureType;

  constructor() {
    effect(() => {
      const isExpanded = this._$isExpanded();
      const code = this._$group().label.toLowerCase().replace(/ /g, '-');
      localStorage.setItem(`sidenav-group-${code}`, String(isExpanded));
    });

    afterNextRender(() => this._expander.set(this._$isExpanded()));
  }

  @HostBinding('class') protected get cssClass(): string[] {
    const isSidenavExpanded = this._layout.$sidenavExpanded();
    const isExpanded = this._$isExpanded();

    const cssClass = ['d-block', 'ps-36', 'pe-12'];

    isSidenavExpanded && cssClass.push('sidenav-expanded');
    isExpanded && cssClass.push('expanded');

    return cssClass;
  }

  showFailedAccountsBadge(label: string): boolean {
    return label === 'Accounts' && this._$client()?.hasPrivilege('opps_view') && this._layout.$sidenavExpanded();
  }

  getErrorDotStyle(label: string): string {
    return this.$accountsErrors().length > 0 && label === 'Accounts' && !this._layout.$sidenavExpanded() ? 'icon__error-dot' : '';
  }

  protected _toggle(): void {
    this._layout.$sidenavExpanded() && this._expander.set(!this._expander());
  }
}
