import { NgClass } from '@angular/common';
import { afterNextRender, ChangeDetectionStrategy, Component, DestroyRef, inject, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { PaginationQueryResult } from '@core/models/search-query.model';
import { DisabledAccount } from '@shared/accounts/models/disabled-account.model';
import { AccountsRestService } from '@shared/accounts/services/accounts-rest.service';
import { Client } from '@shared/clients/models/client.model';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { DialogModalComponent } from '@shared/dialog/components/dialog.component';
import { NgxTableComponent } from '@shared/ngx-table/components/ngx-table/ngx-table.component';
import { NgxTableDatasource } from '@shared/ngx-table/interfaces/ngx-table-datasource.interface';
import { NgxTableModule } from '@shared/ngx-table/ngx-table.module';
import { BehaviorSubject, filter, switchMap, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [NgClass, DialogModalComponent, NgxTableModule, MatIcon, MatButtonModule],
  selector: 'app-disabled-accounts-popup',
  templateUrl: './disabled-accounts-popup.component.html',
  styleUrls: ['./disabled-accounts-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisabledAccountsPopupComponent {
  private __destroyRef = inject(DestroyRef);
  private __router = inject(Router);
  private __clientFacade = inject(ClientFacade);
  private __accountsService = inject(AccountsRestService);

  private __dialogModalCmp = viewChild(DialogModalComponent);
  private __ngxTableCmp = viewChild(NgxTableComponent);

  private __clientCurrent: Client;
  private __clientForDistinction: Client;
  protected _tableSource$ = new BehaviorSubject<NgxTableDatasource<DisabledAccount>>(undefined);
  protected _accountsLength = 0;
  protected _clientName: string;

  constructor() {
    afterNextRender(() => {
      this.__clientFacade.selectedClient$
        .pipe(
          takeUntilDestroyed(this.__destroyRef),
          filter((client) => !!client && this.__clientCurrent?.slug !== client?.slug)
        )
        .subscribe((client) => (this.__clientCurrent = client));

      this.__router.events
        .pipe(
          takeUntilDestroyed(this.__destroyRef),
          filter((e) => e instanceof Scroll && e.routerEvent instanceof NavigationEnd),
          filter(() => !!this.__clientCurrent && this.__clientForDistinction?.slug !== this.__clientCurrent?.slug),
          tap(() => (this.__clientForDistinction = this.__clientCurrent)),
          switchMap(() => this.__accountsService.getDisabledAccounts(this.__clientForDistinction.slug))
        )
        .subscribe((accounts) => !!accounts.length && this.__open(accounts));

      this.__dialogModalCmp()
        .statusChange.pipe(takeUntilDestroyed(this.__destroyRef))
        .subscribe((e) => !!e.afterOpen && this._accountsLength && (this.__ngxTableCmp().pageSize = this._accountsLength));
    });
  }

  private __open(accounts: DisabledAccount[]): void {
    if (!accounts || accounts.length === 0) {
      return;
    }

    const pResult = new PaginationQueryResult<DisabledAccount>();
    pResult.count = 0;
    pResult.current = 0;
    pResult.result = accounts;

    this._accountsLength = accounts.length;

    this._clientName = this.__clientFacade.selectedClient.name;

    this._tableSource$.next(pResult);

    this.__dialogModalCmp().show();
  }

  protected _confirm(): void {
    this.__accountsService.confirmDisabledAccounts(this.__clientFacade.selectedClient.slug).subscribe();
    this.__dialogModalCmp().hide();
  }
}
