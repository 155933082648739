import { CommonModule } from '@angular/common';
import { afterNextRender, ChangeDetectionStrategy, Component, computed, effect, HostBinding, inject, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { OctraNavigation } from '@core/types/octra-navigation.type';
import { ClientsOutletLayoutComponent } from '@layouts/clients-outlet-layout/clients-outlet-layout.component';
import {
  FailedAccountsBadgeComponent,
  FailureType
} from '@shared/accounts/components/failed-accounts-badge/failed-accounts-badge.component';
import { AccountErrors } from '@shared/accounts/types/account-errors.type';
import { AuthFacade } from '@shared/auth-module/store/auth.facade';
import { ClientFacade } from '@shared/clients/store/client.facade';

@Component({
  standalone: true,
  selector: 'client-layout-side-nav-group',
  templateUrl: './client-layout-side-nav-group.component.html',
  styleUrl: './client-layout-side-nav-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule, MatIconModule, CommonModule, MatTooltipModule, FailedAccountsBadgeComponent]
})
export class ClientLayoutSideNavGroupComponent {
  private __clientFacade = inject(ClientFacade);
  private __authFacade = inject(AuthFacade);
  private __$client = this.__clientFacade.$selectedClient;
  private __$user = toSignal(this.__authFacade.user$);
  private __expander = signal<boolean>(undefined);
  private __router = inject(Router);

  layout = inject(ClientsOutletLayoutComponent);
  $isLastChild = input(false);
  $isAccountsPage = signal(this.__router.url.includes('accounts/overview'));
  $group = input.required<OctraNavigation>({
    alias: 'group'
  });
  $accountsErrors = input.required<AccountErrors[]>();
  $routeParams = computed(() => ({
    currentClientSlug: this.__$client()?.slug
  }));
  $isExpanded = computed(() => {
    const group = this.$group();
    const expander = this.__expander();

    if (expander !== undefined) return expander;

    const code = group.label.toLowerCase().replace(/ /g, '-');
    const storage = localStorage.getItem(`sidenav-group-${code}`);

    if (code === 'dev') return false;
    if (!storage) return true;
    return storage === 'true' ? true : false;
  });
  $wasLeft = signal(false);
  $items = computed(() => {
    const group = this.$group();
    const client = this.__$client();
    const user = this.__$user();
    return group?.items?.filter((item) => {
      const isSuperuser = 'forSuperuser' in group && group.forSuperuser === true ? !!user.is_superuser : true;
      const clientAllowed =
        client &&
        (!item.roles || !item.roles.length || client.hasRole(item.roles)) &&
        (!item.privileges || !item.privileges.length || client.hasPrivilege(item.privileges)) &&
        ((!client && group.clientCond !== 'withSelectedClient') || (!!client && client.hasModule(item.requiredModule))) &&
        (!item.clientsNotVisible || !item.clientsNotVisible.includes(client.slug));
      return isSuperuser && clientAllowed;
    });
  });
  failureType = FailureType;

  constructor() {
    effect(() => {
      const isExpanded = this.$isExpanded();
      const code = this.$group().label.toLowerCase().replace(/ /g, '-');
      localStorage.setItem(`sidenav-group-${code}`, String(isExpanded));
    });

    afterNextRender(() => this.__expander.set(this.$isExpanded()));

    this.__router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.$isAccountsPage.set(this.__router.url.includes('accounts/overview'));
      }
    });
  }

  @HostBinding('class') get cssClass(): string[] {
    const isSidenavExpanded = this.layout.$sidenavExpanded();
    const isExpanded = this.$isExpanded();
    const cssClass = ['d-block', 'ps-16', 'pe-12'];

    isSidenavExpanded && cssClass.push('sidenav-expanded');
    isExpanded && cssClass.push('expanded');

    return cssClass;
  }

  showFailedAccountsBadge(label: string): boolean {
    return label === 'Accounts' && this.__$client()?.hasPrivilege('opps_view') && this.layout.$sidenavExpanded();
  }

  toggle(): void {
    this.__expander.set(!this.__expander());
  }
}
