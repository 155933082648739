import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { browserPopupRedirectResolver, initializeAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MAT_ICON_DEFAULT_OPTIONS, MatIconDefaultOptions } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { environment } from '@environments/environment';
import { LayoutsModule } from '@layouts/layouts.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import OktaAuth, { OktaAuthOptions } from '@okta/okta-auth-js';
import { WebsocketService } from '@shared/common/services/websocket.service';
import { SharedModule } from '@shared/shared.module';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoModule } from 'ngx-timeago';
import { ToastrModule } from 'ngx-toastr';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-062617}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{EssenceMediacom}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{OCTRA}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{OCTRA}_need_to_be_licensed___{OCTRA}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{29_July_2025}____[v3]_[01]_MTc1Mzc0MzYwMDAwMA==8e0d4aaeb9cf3ed7c17d2e31b58ded78'
);

export const fbLoginOptions = {
  scope: environment.facebookAuth.scope,
  return_scopes: true
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true
};

const productionOnlyModules: any[] = [];
if (environment.production) {
  if (environment.ga) {
    productionOnlyModules.push(NgxGoogleAnalyticsModule.forRoot(environment.ga));
    productionOnlyModules.push(NgxGoogleAnalyticsRouterModule.forRoot());
  }
}

const matIconDefaultOptions: MatIconDefaultOptions = {
  fontSet: 'material-symbols'
};

const developmentOnlyModules: any[] = [];
if (!environment.production) {
  developmentOnlyModules.push(StoreDevtoolsModule.instrument());
}

const oktaConfig: OktaAuthOptions = {
  issuer: environment.oktaAuth.issuer,
  clientId: environment.oktaAuth.clientId,
  redirectUri: environment.oktaAuth.redirect
};
const oktaAuth = new OktaAuth(oktaConfig);
const oktaModuleConfig: OktaConfig = { oktaAuth };

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutsModule,
    SharedModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    TimeagoModule.forRoot({ formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter } }),
    ToastrModule.forRoot({ preventDuplicates: true }),
    SocialLoginModule,
    OktaAuthModule.forRoot(oktaModuleConfig),
    ...productionOnlyModules,
    ...developmentOnlyModules
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => initializeAuth(getApp(), { popupRedirectResolver: browserPopupRedirectResolver })),
    WebsocketService,
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: MAT_ICON_DEFAULT_OPTIONS, useValue: matIconDefaultOptions },
    { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: { separatorKeyCodes: [ENTER, COMMA] } },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: WebsocketService) => () => {},
      deps: [WebsocketService],
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAuth.clientId, fbLoginOptions)
          }
        ]
      } as SocialAuthServiceConfig
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.googleTag
    }
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
