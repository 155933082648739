import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RouteChangingService } from '@shared/route-change/services/route-changing.service';
import { Observable } from 'rxjs';

@Injectable()
export class LeaveEditModeGuard implements CanDeactivate<unknown> {
  constructor(private onRouteChangingService: RouteChangingService) {}

  canDeactivate(
    _component: unknown,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.onRouteChangingService.setEditState({ editState: false, page: '' });
    return true;
  }
}
