import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GlobalLoaderComponent } from '@shared/loader/components/global-loader/global-loader.component';
import { GlobalLoaderService } from '@shared/loader/services/global-loader.service';

@NgModule({
  imports: [CommonModule],
  declarations: [GlobalLoaderComponent],
  exports: [GlobalLoaderComponent]
})
export class GlobalLoaderSharedModule {
  static forRoot(): ModuleWithProviders<GlobalLoaderSharedModule> {
    return {
      ngModule: GlobalLoaderSharedModule,
      providers: [GlobalLoaderService]
    };
  }
}
