import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, inject, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { APP_ROUTES } from '@environments/routes/app-routes';

@Component({
  selector: 'marketing-outlet-layout',
  templateUrl: './marketing-outlet-layout.component.html',
  styleUrls: ['./marketing-outlet-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingOutletLayoutComponent {
  private __destroyRef = inject(DestroyRef);
  private __router = inject(Router);
  private __title = inject(Title);

  private __$routerOutlet = viewChild(RouterOutlet, { read: ElementRef });
  protected _APP_ROUTES = APP_ROUTES;
  protected _dateNow = new Date();
  protected _$title = signal('Automated campaign intelligence at scale');

  constructor() {
    this.__title.setTitle(`OCTRA - ${this._$title()}`);
    this.__router.events
      .pipe(takeUntilDestroyed(this.__destroyRef))
      .subscribe((evt) => evt instanceof NavigationEnd && this.__$routerOutlet().nativeElement.scrollTo(0, 0));
  }
}
